import React, { useState, ChangeEvent, useEffect, useRef, DragEvent } from 'react';
import axios from 'axios';

import AppHeader from '../header/AppHeader';
import Footer from '../footer/AppFooter';
import Listening from '../components/Listening-screen/Listening';
import Insights from '../components/Insights-loading/Insights-loading';
import PostInsights from '../components/post-insights/PostInsights';
import SpeakerInsights from '../components/speaker-insights/Speaker-insights';
import Loader from '../components/loader/loader';
import { RealTimeInsight, UserInsight } from '../shared/SpeakerInsightTypes';
import Icons from '../assets';
import './SpeakerInsightsPage.scss';
import { getInsightsData, getTranscriptByIdService, getUploadURLService } from '../services/Insights';
import SnackBarComponent, { SnackBarHandle } from '../components/snack-bar/SnackBarComponent';

const SpeakerInsightsPage: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string>('');
  const [domain, setDomain] = useState<string>('');
  const [listeningMessage, setListeningMessage] = useState<string>('No preview available.');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showFileContainer, setShowFileContainer] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [borderColor, setBorderColor] = useState('#BFCCD9');
  const [borderRightColor, setBorderRightColor] = useState('#01BC47');
  const [fileCount, setFileCount] = useState<number>(0);
  const [transcriptData, setTranscriptData] = useState<UserInsight[]>([]);
  const [audioDuration, setAudioDuration] = useState<string | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const ws = useRef<WebSocket | null>(null);
  const [selectedTranscript, setSelectedTranscript] = useState<UserInsight | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const [volumeIcon, setVolumeIcon] = useState(Icons.highVolumeIcon);
  const [showPostInsights, setShowPostInsights] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [currentInsight, setCurrentInsight] = useState<RealTimeInsight | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const isMounted = useRef(false);
  const snackbarRef = useRef<SnackBarHandle>(null);
  const [drag, setDrag] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      getTranscriptData();
      setShowLoader(true);
      setFile(null);
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      const audioElement = audioRef.current;

      audioElement.volume = volume;

      const updateProgress = () => {
        const duration = audioElement.duration || 0;
        const currentTime = audioElement.currentTime || 0;
        const calculatedProgress = (currentTime / duration) * 100 || 0;
        setProgress(calculatedProgress);

        if (selectedTranscript) {
          const insights = selectedTranscript.realTimeInsights;
          const currentInsight = insights.find(
            (insight) =>
              parseFloat(insight.StartTime) <= currentTime && currentTime < parseFloat(insight.StartTime) + 60
          );
          if (currentInsight?.Insights.data?.insights?.length !== 0) {
            setCurrentInsight(currentInsight || null);
          }
        }
      };

      audioElement.addEventListener('timeupdate', updateProgress);

      return () => {
        audioElement.removeEventListener('timeupdate', updateProgress);
      };
    }
  }, [volume, selectedTranscript]);

  const triggerSnackbar = (message: string) => {
    setSnackbarMessage(message);
    if (snackbarRef.current) {
      snackbarRef.current.openSnackbar();
    }
  };

  const handleTranscriptClick = (transcript: React.SetStateAction<UserInsight | null>) => {
    setSelectedTranscript(transcript);
    setShowPostInsights(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset audio to start
      setTimeout(() => {
        audioRef?.current
          ?.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error('Error playing audio:', error);
          });
      }, 1000); // 1 second delay
    }
  };

  const handlePlayClick = (forcePlay = false) => {
    if (audioRef.current) {
      if (isPlaying && !forcePlay) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              console.error('Error playing audio:', error);
            });
        }
      }
    }
  };

  const toggleVolumeControl = () => {
    let newVolume = 1;
    let newIcon = Icons.highVolumeIcon;

    if (volume === 0) {
      newVolume = 0.33;
      newIcon = Icons.lowVolumeIcon;
    } else if (volume === 0.33) {
      newVolume = 0.66;
      newIcon = Icons.mediumVolumeIcon;
    } else if (volume === 0.66) {
      newVolume = 1;
      newIcon = Icons.highVolumeIcon;
    } else if (volume >= 1) {
      newVolume = 0;
      newIcon = Icons.muteIcon;
    }

    setVolume(newVolume);
    setVolumeIcon(newIcon);
  };

  // const handleVolumeChange = (volume: number) => {
  //   if (audioRef.current) {
  //     audioRef.current.volume = volume;
  //   }
  //   setVolume(volume * 100);
  // };

  const formatTime = (timeInSeconds: number): string => {
    if (!timeInSeconds) return '';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const uploadFile = (uploadedFile: File) => {
    if (uploadedFile.size <= 50 * 1024 * 1024) {
      setFile(uploadedFile);
      setFileError('');
      setListeningMessage('Each demo considers an audio length of up to 15 minutes');
      const audioElement = new Audio(URL.createObjectURL(uploadedFile));
      audioElement.onloadedmetadata = () => {
        const durationInSeconds = Math.round(audioElement.duration); // Round to whole seconds
        const durationString = durationInSeconds.toString();
        setAudioDuration(durationString);
      };
    } else {
      setFile(null);
      setFileError('File exceeds the 50MB limit. Please reduce the file size and try again.');
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];

    if (uploadedFile && transcriptData && transcriptData.length < 5) {
      uploadFile(uploadedFile);
    } else {
      triggerSnackbar('Free limit exceeded');
    }
  };

  const handleDomainChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
    if (e.target.value) {
      setListeningMessage('Upload a sound clip to preview summarization.');
    }
  };

  const options = ['Banking and Finance', 'Healthcare', 'Airline', 'Telecommunications', 'Other'];

  const handleOptionClick = (option: string) => {
    setDomain(option);
    setListeningMessage('Upload a sound clip to preview summarization.');
    setDropdownVisible(false);
    setBorderColor('#BFCCD9');
  };

  const getTranscriptData = async () => {
    try {
      const response = await getInsightsData();
      // Assuming setTranscriptData expects the data property of the response
      setTranscriptData(response);
      setFileCount(response.length || 0);
      setShowLoader(false);
    } catch (error) {
      triggerSnackbar('Error getting data');
      console.error('Error fetching insights data: ', error);
    }
    setShowLoader(false);
  };

  const getTranscriptById = async (id: any) => {
    try {
      const response = await getTranscriptByIdService(id);
      return response;
    } catch (error) {
      console.error('Error fetching insights data: ', error);
    }
  };

  const getUploadURL = async () => {
    try {
      const uploadData = await getUploadURLService(domain, file, audioDuration);
      return uploadData;
    } catch (error) {
      triggerSnackbar('Error while uploading file');
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setFileError('Please upload a file.');
      return;
    }

    if (!domain) {
      triggerSnackbar('Please select a domain.');
      setFileError('Please select a domain.');
      return;
    }

    if (!audioDuration) {
      setFileError('Failed to get audio duration. Please try again.');
      return;
    }
    setFileError('');

    try {
      setShowFileContainer(true);
      const presignedData = await getUploadURL();

      if (presignedData?.url && file) {
        const formData = new FormData();
        Object.keys(presignedData?.fields).forEach((key) => {
          formData.append(key, presignedData?.fields[key]);
        });
        formData.append('file', file);
        setFileCount((prevCount) => prevCount + 1);
        const uploadResponse = await axios.post(presignedData?.url, formData, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(progress);
              if (progress >= 100) {
                setListeningMessage('Almost there, Processing your sound clip..');
              }
            } else {
              console.log('Length not computable for progressEvent:', progressEvent);
            }
          },
        });
        if (uploadResponse.status === 204) {
          console.log('File uploaded successfully');
          connectToWebSocket(presignedData);
        } else {
          console.error('Failed to upload file. Status:', uploadResponse.status);
          setFile(null);
        }
      } else {
        triggerSnackbar('Error while uploading file');
        setFile(null);
        console.error('uploadURL or file is undefined');
      }
    } catch (error) {
      triggerSnackbar('Error while uploading file');
      setShowFileContainer(false);
      setFile(null);
    }
  };

  const connectToWebSocket = (presignedData: {
    url?: string;
    fields?: { [key: string]: string };
    userId: any;
    transcriptId: any;
  }) => {
    const socket = new WebSocket(`${process.env.REACT_APP_SYNOPSIS_WEBSOCKET_API}/?userId=${presignedData?.userId}`);
    ws.current = socket;

    socket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    socket.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      const message = data.message;
      if (message.transcriptId === presignedData?.transcriptId) {
        if (message.status === 'completed') {
          setListeningMessage('Processing complete. Fetching transcript...');
          const transcriptData = await getTranscriptById(presignedData?.transcriptId);
          if (transcriptData) {
            setTranscriptData((prevData) => (prevData ? [...prevData, transcriptData] : [transcriptData]));
          }
          setFile(null);
          setDomain('');
          setShowFileContainer(false);
          socket.close();
        } else {
          setListeningMessage(`Processing`);
        }
      }
    };

    socket.onerror = (error) => {
      setShowFileContainer(false);
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      setShowFileContainer(false);
      console.log('WebSocket connection closed');
    };
  };

  const handleFocus = () => {
    setDropdownVisible(true);
    setBorderColor('#534ad8');
  };

  const handleViewPostInsights = () => {
    setShowPostInsights(!showPostInsights);
  };

  const handleCancel = () => {
    setFile(null);
    setDomain('');
    setListeningMessage('No preview available');
    if (fileCount > 0) {
      setFileCount((prevCount) => prevCount - 1); // Decrement file count on cancel
    }
  };

  useEffect(() => {
    if (transcriptData && transcriptData.length >= 5) {
      setBorderRightColor('red');
    } else {
      setBorderRightColor('#01BC47');
    }
  }, [transcriptData]);

  const formatTimeHour = (timeInSeconds: number): string => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const formattedTime = `${hours > 0 ? hours + 'h ' : ''}${minutes}mins`;
    return formattedTime;
  };
  const handleReplayClick = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const truncateFilename = (filename: string, maxLength: number) => {
    const [name, extension] = filename.split(/(?=\.[^.]+$)/);
    if (name.length <= maxLength) {
      return filename;
    }
    return `${name.substring(0, maxLength)}...${extension}`;
  };

  const handleDrop = (event: DragEvent<any>) => {
    event.preventDefault();
    setDrag(false);
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const uploadedFile = newFiles[0];
      if (uploadedFile && transcriptData && transcriptData.length < 5) {
        uploadFile(uploadedFile);
      } else {
        triggerSnackbar('Free limit exceeded');
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(false);
  };

  return (
    <div className="main-div">
      {showLoader && <Loader />}
      <SnackBarComponent ref={snackbarRef} snackbarMessage={snackbarMessage} />
      <AppHeader />
      <section className="section">
        <div className="main-title">
          <div className="gallery-title">
            <h2>Synopsis for Events</h2>
            <p>Explore the capabilities and the future of summarization technology.</p>
          </div>

          {transcriptData && transcriptData.length > 0 && (
            <div
              className="file-limit-alert"
              style={{
                borderRight: `4px solid ${borderRightColor}`,
              }}
            >
              <span className="remaining-file">
                Remaining free file limit <span>{5 - (transcriptData.length || 0)}</span>/5
              </span>
              <span className="audio-length">Each demo considers an audio length of up to 10 minutes</span>
            </div>
          )}
        </div>

        <div className="box">
          <div
            className={`video-upload-container ${transcriptData?.length === 5 ? 'apply-opacity' : ''} ${drag ? 'upload' : ''}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <img src={Icons.document} alt="document icon" />
            {file && !showFileContainer ? (
              <>
                <div className="file-name">{file.name}</div>
                <div className="select-input">
                  <div className="input-dropdown-container">
                    <input
                      type="text"
                      value={domain}
                      onChange={handleDomainChange}
                      placeholder="Select Domain"
                      onFocus={handleFocus}
                      className="domain-input"
                      style={{ borderBottom: `1px solid ${borderColor}` }}
                    />
                    <button onClick={() => setDropdownVisible(!dropdownVisible)} className="dropdown-button">
                      <img src={Icons.dropdown} alt="dropdown icon"></img>
                    </button>

                    {dropdownVisible && (
                      <div className="dropdown-menu-container">
                        <ul className="dropdown-menu">
                          <div className="dropdown-title">Select / Search</div>
                          {options.map((option, index) => (
                            <li key={index} onClick={() => handleOptionClick(option)} className="dropdown-item">
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="upload-cancel-button-container">
                    <button className="upload" onClick={handleUpload}>
                      Upload
                    </button>
                    <button className="cancel" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {fileError ? (
                  <p className="error-text">{fileError}</p>
                ) : (
                  <div className="upload-text">Drag & drop or browse to upload audio (Max 50MB)</div>
                )}
                <input
                  type="file"
                  accept=".mp4,.mp3,.wav"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="file-upload"
                  disabled={showFileContainer}
                />
                <label htmlFor="file-upload" className="button-container">
                  Browse
                </label>
              </>
            )}
          </div>
        </div>

        <div className="file-wrapper">
          {transcriptData &&
            transcriptData.length > 0 &&
            transcriptData.map((transcript, index) => (
              <div
                key={index}
                className={`file-card ${
                  selectedTranscript?.postInsights.TranscriptID === transcript.postInsights.TranscriptID
                    ? 'selected-file'
                    : ''
                }`}
                onClick={() => handleTranscriptClick(transcript)}
              >
                <div className="file-container">
                  <img src={Icons.document} alt="document icon" />
                  <div className="file-name">
                    <span className="file-title">{truncateFilename(transcript?.audioData?.metadata.name, 25)}</span>
                    <div className="play">
                      <img src={Icons.playCircle} alt="play icon" />
                      <span className="play-title">Play</span>
                      <span className="duration">
                        <img src={Icons.sepDotIcon} alt="" />{' '}
                        {formatTimeHour(parseFloat(transcript.audioData.metadata.duration))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {file && showFileContainer && (
            <div className={`file-card `}>
              <div className="file-container">
                <img src={Icons.document} alt="document icon" />
                <div className="file-name">
                  <span className="file-title">{truncateFilename(file?.name, 25)}</span>
                  {uploadProgress < 100 && (
                    <div className="play">
                      <img src={Icons.playCircle} alt="play icon" />
                      <span className="play-title">Play</span>
                      <span className="duration"></span>
                    </div>
                  )}
                  {uploadProgress >= 100 && (
                    <div
                      className={`processing-container ${showFileContainer && uploadProgress >= 100 ? 'blink' : ''}`}
                    >
                      <img src={Icons.timeLapse} alt="processing"></img>
                      <div>Processing</div>
                    </div>
                  )}
                </div>
              </div>
              {uploadProgress < 100 && (
                <div className="progress-bar-container">
                  <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                </div>
              )}
            </div>
          )}
        </div>

        {selectedTranscript && (
          <>
            <div className="listening-container">
              <div className="file-info">
                <div>{selectedTranscript.audioData.metadata.name}</div>
                <div className="buttons">
                  <button className="replay-button" onClick={handleReplayClick}>
                    <img src={Icons.repeat} alt="play icon" />
                    Replay
                  </button>
                  <button className="report-button" onClick={() => handleViewPostInsights()}>
                    <img src={Icons.viewIcon} alt="view icon" />
                    View Post Session Report
                  </button>
                </div>
              </div>
              {!showPostInsights && (
                <div>
                  <div className="audio-play">
                    <span>
                      <img src={Icons.skipNext} alt="skip next" />
                    </span>
                    <span onClick={() => handlePlayClick()}>
                      <img
                        src={isPlaying ? Icons.pauseIcon : Icons.playArrow}
                        alt={isPlaying ? 'pause icon' : 'play icon'}
                      />
                    </span>
                    <span>
                      <img src={Icons.skipPrevious} alt="skip previous" />
                    </span>
                    <div className="progress-bar-container">
                      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                    </div>
                    <span className="duration-time">
                      {audioRef.current ? formatTime(audioRef.current.duration) : '0:00'}
                    </span>
                    {/* <span
                      className="volume-container"
                      onClick={toggleVolumeControl}
                    >
                      <img src={volume_up} alt="volume icon" /> */}
                    {/* {showVolumeControl && (
                        <input
                          type="range"
                          min="0"
                          max="100"
                          value={volume}
                          onChange={handleVolumeChange}
                          className="volume-slider"
                          style={{ transform: "rotate(-90deg)" }}
                        />
                      )} */}

                    {/* </span> */}
                    <span className="volume-container">
                      <img src={volumeIcon} alt="volume icon" onClick={toggleVolumeControl} />
                    </span>
                  </div>

                  <audio ref={audioRef} src={selectedTranscript.audioData.url}></audio>
                </div>
              )}
              {!showPostInsights ? (
                currentInsight?.Insights.data.insights ? (
                  <SpeakerInsights insights={currentInsight?.Insights.data.insights || []} />
                ) : (
                  <Insights />
                )
              ) : (
                <PostInsights postInsight={selectedTranscript.postInsights} />
              )}
            </div>
          </>
        )}
        {!selectedTranscript && <Listening message={listeningMessage} />}
      </section>
      <Footer />
    </div>
  );
};

export default SpeakerInsightsPage;
