import React from 'react';
import '../Listening-screen/Listening.scss';
import Icons from '../../assets';
import loading from '../../assets/images/Loading Style 1.svg';
interface ListeningProps {
  message: string;
}

const Listening: React.FC<ListeningProps> = ({ message }) => {
  return (
    <div className="main-div">
      <div className="listening-conatnaier1">
        <div className="listening">
          <img src={Icons.voiceEffect} alt="voice effect" />
          <div className="listening-preview">
            <img
              src={loading}
              alt="loading"
              className="loading-animation" // Apply animation class here
            />
            <span className='preview-text'>{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listening;
