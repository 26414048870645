import React from 'react';
import "../synopsis-footer/Synopsis-screen-footer.scss";
import rozie_logo from "../../assets/images/Rozie-PurpleBlue-Logo .svg";
const synopsis_screen_footer  = () => {
return (
    <>
    <div className='synopsis-footer'>
        <span className='powered'>Powered by </span>
        <img src={rozie_logo } alt='rozie logo'></img>
        <span className='synopsis-event'>Synopsis for Events</span>
    </div>
</>
);

};
export default synopsis_screen_footer;