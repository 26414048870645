// src/services/InterceptorHelpers.ts
import { AxiosInstance, AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import AuthService from './AuthService';

let isRefreshing = false;
let failedQueue: Array<{
  config: InternalAxiosRequestConfig & { _retry?: boolean };
  resolve: (value: AxiosResponse<any>) => void;
  reject: (error: any) => void;
}> = [];

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = AuthService.getIdToken();
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

      if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise<AxiosResponse<any>>((resolve, reject) => {
            failedQueue.push({ config: originalRequest, resolve, reject });
          })
            .then((response) => response)
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newToken = await AuthService.refreshAccessToken();
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          processQueue(null, newToken, axiosInstance);
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          processQueue(err, null, axiosInstance);
          AuthService.logout(); // Force logout when token refresh fails
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    }
  );
};

const processQueue = (error: any, token: string | null = null, axiosInstance: AxiosInstance) => {
  failedQueue.forEach(async (prom) => {
    if (token) {
      prom.config.headers = prom.config.headers || {};
      prom.config.headers['Authorization'] = `Bearer ${token}`;
      try {
        const response = await axiosInstance(prom.config);
        prom.resolve(response);
      } catch (err) {
        prom.reject(err);
      }
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};
