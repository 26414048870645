import React, { createContext, useContext, useState, ReactNode } from 'react';

interface VideoContextType {
    title: string;
    description: string;
    videoUrl: string;
    setVideoInfo: (title: string, description: string, videoUrl: string) => void;
}

const VideoContext = createContext<VideoContextType | undefined>(undefined);

export const useVideoContext = () => {
    const context = useContext(VideoContext);
    if (!context) {
        throw new Error('useVideoContext must be used within a VideoProvider');
    }
    return context;
};

interface VideoProviderProps {
    children: ReactNode;
}

export const VideoProvider: React.FC<VideoProviderProps> = ({ children }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [videoUrl, setVideoUrl] = useState('');

    const setVideoInfo = (newTitle: string, newDescription: string, newVideoUrl: string) => {
        setTitle(newTitle);
        setDescription(newDescription);
        setVideoUrl(newVideoUrl);
    };

    return (
        <VideoContext.Provider value={{ title, description, videoUrl, setVideoInfo }}>
            {children}
        </VideoContext.Provider>
    );
};

