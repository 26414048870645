import React from "react";
import "../Insights-loading/Insights-loading.scss";
import voice_effect from "../../assets/images/Voice Effect (1).svg";
import SynopsisScreenFooter from "../synopsis-footer/Synopsis-screen-footer";

const Listening: React.FC = () => {
  return (
    <div className="main-div-container">   
      <div className="synposis-screen">
        <div className="listening">
          <img src={voice_effect} alt="voice effect" />
          <div className="preview">
            <span className="preview_title">Listening...</span>
          </div>
        </div>
        <div className="footer123">
          <SynopsisScreenFooter />
        </div>
      </div>
    </div>
  );
};

export default Listening;

