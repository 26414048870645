import React, { useState, useRef, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga4';

import './AppOtp.scss';
import { OTPVerification, resendOtp } from '../services/Login';

interface EnterOTPProps {}

const AppOtp: React.FC<EnterOTPProps> = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [resendClicked] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email') ?? '';
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (otp.every((block) => block !== '')) {
      const fakeEvent = new Event('submit') as unknown as FormEvent<HTMLFormElement>;
      handleSubmit(fakeEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const handleOtpChange = (index: number, value: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(value)) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (!email) {
      console.error('Email is null');
      return;
    }

    const inputOtp = otp.join('');

    ReactGA.event({
      category: 'OTP',
      action: 'Submission',
      label: 'OTP Submission',
    });

    callOtpVerificationAPI(inputOtp);
  };

  const callOtpVerificationAPI = async (otp: string) => {
    const responseData = await OTPVerification(email, otp);
    if (responseData && responseData.AuthenticationResult.IdToken) {
      navigate('/videos');
    }
  };

  const handleResendOTP = async () => {
    await resendOtp(email);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text');
    const otpArray = pasteData.split('').slice(0, 6);

    inputRefs.current[5]?.focus();
    const newOtp = [...otp];
    otpArray.forEach((digit, index) => {
      if (inputRefs.current[index]) {
        newOtp[index] = digit;
      }
    });
    setOtp(newOtp);
  };

  return (
    <div className="otp-container">
      <Toaster />
      <div className="otp-left-side"></div>
      <div className="otp-right">
        <div className="otp-bg"></div>
        <div className="enter-otp">
          <h2>Have you received a code?</h2>
          <h3>A one time code has sent to your email</h3>
          <h4>Enter 6 digits code</h4>
          <form onSubmit={handleSubmit}>
            <div className="otp-inputs">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="number"
                  //placeholder="0"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)} // Use onKeyDown event
                  onPaste={handlePaste}
                  maxLength={1}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                  required
                />
              ))}
            </div>
            <button type="submit">Verify</button>
          </form>
          <div className="otp-resend-container">
            <h5>Didn't receive the code?</h5>
            {!resendClicked && (
              <p className="otp-resend-link" onClick={handleResendOTP}>
                Resend
              </p>
            )}
            {resendClicked && <p>OTP has been resent</p>}
          </div>
          <div className="otp-footer">
            <div className="rights">© 2024 RozieAI.</div>

            <div className="Privacy">
              <a href="/assets/docs/RozieAI+Privacy+Policy_web_version_v1.pdf" target="_blank">
                Privacy Policy
              </a>
              {/* commented out because this wasn't used */}
              {/* <a href="https://www.rozie.ai/s/RozieAI-Privacy-Policy_web_version_v1.pdf" target="_blank" rel="noreferrer"></a> */}
            </div>

            <div className="social-media">
              <a href="https://www.facebook.com/rozieai/" target="_blank" rel="noreferrer">
                <img src="assets/img/all/facebook-round.svg" alt="Facebook"></img>
              </a>
              <a href="https://twitter.com/RozieAi" target="_blank" rel="noreferrer">
                <img src="assets/img/all/twitter.svg" alt="Twitter"></img>
              </a>
              <a href="https://www.linkedin.com/company/rozieai/" target="_blank" rel="noreferrer">
                <img src="assets/img/all/linkedin.svg" alt="LinkedIn"></img>
              </a>
            </div>
          </div>
        </div>
        <p className="otp-sub">
          Disclaimer: By proceeding with login, you agree to receive a one-time password (OTP) via SMS or email to
          authenticate your identity. Your phone number or email address will only be used for the purpose of sending
          this OTP and will not be shared with any third parties. Please ensure that you have access to the provided
          phone number or email ID to complete the login process. If you encounter any issues, please contact our
          support team for assistance.
        </p>
      </div>
    </div>
  );
};

export default AppOtp;
