import React, { useState } from 'react';
import '../post-insights/PostInsights.scss';
import SessionContent from './session-content/SessionContent';
import { TEMPLATE_DATA } from '../../shared/Constants';

type PostInsight = {
  title: string;
  summary: string;
  key_takeaways: string[];
  insights: string[];
  topics: string[];
  speakers: string[];
};
type PostInsightData = {
  data: PostInsight;
};

type PostInsightDetails = {
  Timestamp: string;
  UserID: string;
  Domain: string;
  PostInsights: PostInsightData;
  TranscriptID: string;
  Name: string;
};

type PostInsightsProps = {
  postInsight: PostInsightDetails;
};
const PostInsights: React.FC<PostInsightsProps> = ({ postInsight }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>('summary');
  const templateData = TEMPLATE_DATA;

  const handleTemplateClick = (template: { type: string }) => {
    setSelectedTemplate(template.type);
  };

  return (
    <div className="main-div">
      <div className="template-container-row">
        {templateData.map((template) => (
          <div
            key={template.type}
            className={`template-container ${template.type}-template ${selectedTemplate === template.type ? '' : ''}`}
            onClick={() => handleTemplateClick(template)}
          >
            <div className="post-insights-screen">
              <div className="post-insights-screen-title">{template.title}</div>
              <div className="template-img">
                <img
                  src={template.icon}
                  alt={`${template.type} icon`}
                  style={{
                    border: selectedTemplate === template.type ? '2px solid blue' : 'none',
                    borderRadius: '12px',
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <SessionContent
        selectedTemplate={selectedTemplate ?? ''}
        summary={postInsight.PostInsights.data.summary}
        key_takeaways={postInsight.PostInsights.data.key_takeaways}
        insights={postInsight.PostInsights.data.insights}
        topics={postInsight.PostInsights.data.topics}
      />
    </div>
  );
};

export default PostInsights;
