import React from 'react';
import SynopsisScreenFooter from '../../synopsis-footer/Synopsis-screen-footer';
import './SessionContent.scss';

type Props = {
  summary: string;
  selectedTemplate: string;
  key_takeaways: string[];
  insights: string[];
  topics: string[];
};

export enum Titles {
  'summary' = 'Summary',
  'key-takeaways' = 'Key Takeaways',
  'recommendations' = 'Recommendations',
  'topics' = 'Top Topics',
}

const SessionContent = ({ summary, key_takeaways, insights, topics, selectedTemplate }: Props) => {
  const icon: string = selectedTemplate === 'summary' ? '📝' : '⭐';
  const title: string = Titles[selectedTemplate as keyof typeof Titles];

  const renderContent = (): JSX.Element | JSX.Element[] => {
    let tempContent = <></>;
    switch (selectedTemplate) {
      case 'summary':
        return (
          <div className="take-ways summary">
            <div>
              <span>{summary}</span>
            </div>
          </div>
        );
      // break;

      case 'key-takeaways':
        if (key_takeaways?.length) {
          return (
            <div className="take-ways-content">
              {key_takeaways.map((key_takeaway, index) => (
                <div className="take-ways key" key={index}>
                  <div className="icon">✅ </div>
                  <div>
                    <span>{key_takeaway}</span>
                  </div>
                </div>
              ))}
            </div>
          );
        }

        break;

      case 'recommendations':
        if (insights?.length) {
          return (
            <div className="recommendations-content">
              {insights.map((insight, index) => (
                <div className="take-ways key" key={index}>
                  <div className="icon">✅ </div>
                  <div>
                    <span className="insight-title">{insight}</span>
                  </div>
                </div>
              ))}
            </div>
          );
        }

        break;
      case 'topics':
        return (
          <div className="circle-container">
            {topics.map((topic, index) => (
              <div className="circle" key={index}>
                <span className="topic-title">{topic}</span>
              </div>
            ))}
          </div>
        );

      default:
        break;
    }

    return tempContent;
  };

  return (
    <div className="main-div">
      <div className="synopsis-screen">
        <div className="header-card-session">
          <span className="welcome">
            <span className="icon">✈️ </span> <span className="text">Session</span> debrief
          </span>
        </div>

        <div className="snapShot">
          <div className="snapShotPoint">
            <div className="icon">{icon}</div>
            <div>
              <span className="title">{title}</span>
            </div>
          </div>
          {renderContent()}
        </div>
        <div className="footer123">
          <SynopsisScreenFooter />
        </div>
      </div>
    </div>
  );
};

export default SessionContent;
