// Loader.tsx
import React from 'react';
import '../loader/loader.scss'; // You can style the loader using this CSS file

const Loader: React.FC = () => {
  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
