// src/assets/index.ts
import rozieLogo from './images/rozie-logo.svg';
import hamburgerIcon from './svg/hamburger.svg';
import mobileLogo from './svg/rozielogo_mobile.svg';
import menuClose from './svg/menu_close.svg';
import keyboardArrowRight from './svg/keyboard_arrow_right.svg';
import twitterIcon from './svg/twitter.svg';
import linkedinIcon from './svg/linkedin.svg';
import back from './svg/back-arrow.svg';
import voiceEffect from './svg/voice-effect.svg';
import roziePurpleBlueLogo from './svg//rozie-purple-blue-logo.svg';

import playCircle from './images/play_circle.svg';
import document from './images/Document Type Icon.svg';
import dropdown from './images/keyboard_arrow_down.svg';
import skipNext from './images/skip_next.svg';
import skipPrevious from './images/skip_previous.svg';
import playArrow from './images/play_arrow.svg';
import pauseIcon from './images/pause.svg';
import repeat from './images/repeat.svg';
import viewIcon from './images/remove_red_eye.svg';
import sepDotIcon from './images/Sep Dot.svg';
import timeLapse from './images/timelapse.svg';
import lowVolumeIcon from './images/Volume-Low.svg';
import highVolumeIcon from './images/Volume-High.svg';
import mediumVolumeIcon from './images/Volume-Medium.svg';
import muteIcon from './images/Volume-Mute.svg';
import summaryIcon from './images/summary.svg';
import keyTakeAwayIcon from './images/keytakeway.svg';
import recommendationIcon from './images/recomdation.svg';
import topicIcon from './images/topic.svg';

const Icons = {
  rozieLogo,
  hamburgerIcon,
  mobileLogo,
  menuClose,
  keyboardArrowRight,
  twitterIcon,
  linkedinIcon,
  playCircle,
  document,
  dropdown,
  skipNext,
  skipPrevious,
  playArrow,
  pauseIcon,
  repeat,
  viewIcon,
  sepDotIcon,
  timeLapse,
  lowVolumeIcon,
  highVolumeIcon,
  mediumVolumeIcon,
  muteIcon,
  back,
  voiceEffect,
  roziePurpleBlueLogo,
  summaryIcon,
  keyTakeAwayIcon,
  recommendationIcon,
  topicIcon,
};

export default Icons;
