import { CognitoIdentityServiceProvider } from 'aws-sdk';
import ReactGA from 'react-ga4';
import AWS from 'aws-sdk';
import toast from 'react-hot-toast';
import ApiBase from './ApiService';
import { AxiosError } from 'axios';
import AuthService from './AuthService';
import { AuthResponse } from '../shared/Types';

AWS.config.update({
  region: 'ca-central-1',
});

const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();

export const signUp = async (email: string) => {
  const clientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
  if (!clientId) {
    console.error('REACT_APP_USER_POOL_WEB_CLIENT_ID is not defined');
    return;
  }

  const params = {
    ClientId: clientId,
    Password: 'TempPassword123!',
    Username: email,
    UserAttributes: [
      {
        Name: 'email',
        Value: email,
      },
    ],
  };

  try {
    await cognitoIdentityServiceProvider.signUp(params).promise();

    ReactGA.event({
      category: 'User',
      action: 'Signup',
      label: 'User Signup',
    });

    ReactGA.set({ userId: email });

    return await callCustomChallengeAPI(email);
  } catch (error: any) {
    if (error.code === 'UsernameExistsException') {
      return await callCustomChallengeAPI(email);
    } else {
      console.error('Error signing up user:', error);
      if (error.code === 'UserNotConfirmedException') {
        try {
          await cognitoIdentityServiceProvider
            .resendConfirmationCode({
              ClientId: clientId,
              Username: email,
            })
            .promise();
          console.log('OTP resent successfully.');
        } catch (resendError) {
          console.error('Error resending OTP:', resendError);
        }
      }
    }
  }
};

export const callCustomChallengeAPI = async (email: string) => {
  const requestBody = {
    AuthParameters: {
      USERNAME: email,
      CHALLENGE_NAME: 'CUSTOM_CHALLENGE',
    },
    AuthFlow: 'CUSTOM_AUTH',
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  };

  try {
    const response = await ApiBase.post<{ __type: string; Session: any }>(
      '/',
      requestBody,
      undefined,
      undefined,
      'initiateAuth'
    );

    const responseData = response.data;
    if (responseData.__type !== 'NotAuthorizedException') {
      const sessionToken = responseData.Session;
      localStorage.setItem('sessionToken', sessionToken);
      return {
        success: true,
        message: '',
      };
    } else {
      return {
        success: false,
        message: "Sorry, seems like we don't have your email address in our database",
      };
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response && error.response.data.__type === 'NotAuthorizedException') {
        console.error('NotAuthorizedException:', error.response.data);
        return {
          success: false,
          message: "Sorry, seems like we don't have your email address in our database",
        };
      }
    }

    console.error('Error calling custom challenge API:', error);
    return {
      success: false,
      message: 'Error calling custom challenge API',
    };
  }
};

export const requestAccess = async (email: string) => {
  try {
    const response = await ApiBase.post('/', { email }, undefined, 'requestAccess', 'requestAccess');

    if (response.status === 200) {
      console.log('Request for access sent successfully.');
      return true;
    } else {
      console.error('Failed to send request for access:', response.statusText);
      return false;
    }
  } catch (error) {
    console.error('Error sending request for access:', error);
    return false;
  }
};

export const OTPVerification = async (email: string, otp: string) => {
  const requestBody = {
    ChallengeName: 'CUSTOM_CHALLENGE',
    ChallengeResponses: {
      USERNAME: email,
      ANSWER: otp,
    },
    AuthFlow: 'CUSTOM_AUTH',
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    Session: localStorage.getItem('sessionToken'),
  };

  try {
    const response = await ApiBase.post<AuthResponse>('/', requestBody, undefined, undefined, 'respondToAuthChallenge');

    if (response.data.AuthenticationResult && response.data.AuthenticationResult.IdToken) {
      AuthService.saveAuthInLocal(response.data);
      return response.data;
    } else {
      toast.error('Can you please verify the OTP again?');
      console.error('Error calling custom challenge API. Response:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error calling custom challenge API:', error);
    return null;
  }
};

export const resendOtp = async (email: string) => {
  ReactGA.event({
    category: 'OTP',
    action: 'Resend',
    label: 'OTP Resend',
  });

  const requestBody = {
    AuthParameters: {
      USERNAME: email,
      CHALLENGE_NAME: 'CUSTOM_CHALLENGE',
    },
    AuthFlow: 'CUSTOM_AUTH',
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  };

  try {
    const response = await ApiBase.post<{ Session: any }>('/', requestBody, undefined, undefined, 'initiateAuth');

    const responseData = response.data;

    if (responseData.Session) {
      localStorage.setItem('sessionToken', responseData.Session);
    } else {
      console.error('Error resending OTP. Response:', responseData);
    }
  } catch (error) {
    console.error('Error resending OTP:', error);
  }
};
