import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './App.scss';
import React, { useEffect } from 'react';
import AppVideos from './videos/AppVideos';
import AppVideoPlayer from './videoplayer/AppVideoPlayer';
import AppLogin from './login/AppLogin';
import AppOtp from './otp/AppOtp';
import ReactGA from 'react-ga4';
import { VideoProvider } from '../src/context/useVideoContext';
import AuthService from './services/AuthService';
import SpeakerInsightsPage from './speaker-insights-page/SpeakerInsightsPage';

const TRACKING_ID = 'G-N0F2DBZB2J';
ReactGA.initialize([
  {
    trackingId: TRACKING_ID,
  },
]);
function App() {
  const location = useLocation();
  const navigate = useNavigate();

  // Set the navigate function in AuthService
  React.useEffect(() => {
    AuthService.setNavigateFunction(navigate);
  }, [navigate]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return (
    <VideoProvider>
      <div>
        <Routes>
          <Route path="/videos" element={<AppVideos />} />
          <Route path="/synopsis" element={<SpeakerInsightsPage />} />
          <Route path="/login" element={<AppLogin />} />
          <Route path="/otp" element={<AppOtp />} />

          <Route path="/videoplayer" element={<AppVideoPlayer />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </VideoProvider>
  );
}

export default App;
