import React from 'react';
import './AppCardSkeleton.scss';

const AppCardSkeleton = () => {
    return (
        <div className="card">
            <div className="cover"></div>
            <div className="info">
                <div className="line"></div>
                <div className="desc">
                    <div className="left-rect"></div>
                    <div className="right-rect"></div>
                </div>
            </div>
        </div>
    );
};

export default AppCardSkeleton;
