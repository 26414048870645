// src/services/Insights.ts
import { ApiResponse, UserInsight } from '../shared/SpeakerInsightTypes';
import ApiService from './ApiService';

export const getInsightsData = async () => {
  const apiURL = '/get-insights-data';
  const requestBody = {
    case: 'getByUserId',
  };

  try {
    const response = await ApiService.post<{ userInsights: any }>(
      apiURL,
      requestBody,
      undefined,
      'synopsis',
      'synopsis'
    );
    return response.data?.userInsights;
  } catch (error) {
    console.error('Error fetching insights data: ', error);
    throw new Error('Error getting data');
  }
};

export const getUploadURLService = async (domain: string, file: File | null, audioDuration: string | null) => {
  const apiURL = `/generate-upload-url`;

  const requestBody = {
    domain,
    name: file?.name,
    duration: audioDuration,
  };

  try {
    const response = await ApiService.post<ApiResponse>(apiURL, requestBody, undefined, 'synopsis', 'synopsis');

    const uploadData = {
      url: response.data.url,
      fields: response.data.fields,
      userId: response.data.userId,
      transcriptId: response.data.transcriptId,
    };

    return uploadData;
  } catch (error) {
    console.error('Error fetching upload URL: ', error);
    throw new Error('Error fetching upload URL');
  }
};

export const getTranscriptByIdService = async (id: any) => {
  const apiURL = '/get-insights-data';

  const requestBody = {
    case: 'getByTranscriptId',
    transcriptId: id,
  };

  try {
    const response = await ApiService.post<UserInsight>(apiURL, requestBody, undefined, 'synopsis', 'synopsis');
    return response.data;
  } catch (error) {
    console.error('Error fetching insights data: ', error);
    throw new Error('Error fetching insights data');
  }
};
