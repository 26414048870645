import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React, { useState, useImperativeHandle, forwardRef } from 'react';

type Props = {
  snackbarMessage: string;
};

export interface SnackBarHandle {
  openSnackbar: () => void;
}

const SnackBarComponent = forwardRef<SnackBarHandle, Props>(({ snackbarMessage }, ref) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackBarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openSnackbar: () => {
      setSnackbarOpen(true);
    },
  }));

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackBarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleSnackBarClose}
        severity="error"
        sx={{
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '8px',
        }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
});

export default SnackBarComponent;
