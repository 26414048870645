import React from 'react';
import '../speaker-insights/Speaker-insights.scss';
import SynopsisScreenFooter from '../synopsis-footer/Synopsis-screen-footer';
interface ListeningProps {
  insights: string[];
}

const SpeakerInsights: React.FC<ListeningProps> = ({ insights }) => {
  return (
    <div className="main-div">
      <div className="synposis-screen">
        <div className="header-card">
          <span className="welcome">
            <span className="icon">✈️ </span> <span className="text">Speaker</span> Insights
          </span>
        </div>
        <div className="listening">
          {/* <img src={voice_effect} alt="voice effect" /> */}
          {insights &&
            insights.length &&
            insights.map((insight, index) => (
              <div className="preview" key={index}>
                <span className="preview_title">“{insight}”</span>
              </div>
            ))}
        </div>
        <SynopsisScreenFooter />
      </div>
    </div>
  );
};

export default SpeakerInsights;
