// src/services/ApiService.ts
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { setupInterceptors } from './InterceptorHelpers';

class ApiService {
  private static instance: ApiService;
  private axiosInstance: AxiosInstance;
  private baseURLs: Record<string, string>;
  private defaultHeaders: Record<string, Record<string, string>>;

  private constructor() {
    this.axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.baseURLs = {
      default: process.env.REACT_APP_API_END_POINT || '',
      synopsis: process.env.REACT_APP_SYNOPSIS_API || '',
      webClient: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
      requestAccess: process.env.REACT_APP_REQUEST_ACCESS_API || '',
      // Add more base URLs as needed
    };

    this.defaultHeaders = {
      default: {
        'Content-Type': 'application/json',
      },
      initiateAuth: {
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        'Content-Type': 'application/x-amz-json-1.1',
      },
      respondToAuthChallenge: {
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
        'Content-Type': 'application/x-amz-json-1.1',
      },
      synopsis: {
        Authorization: localStorage.getItem('idToken') || '',
        'Content-Type': 'application/json',
      },
      webClient: {
        'Content-Type': 'application/json',
        'Custom-Header': 'AnotherServiceHeader',
      },
      requestAccess: {
        'x-api-key': process.env.REACT_APP_REQUEST_ACCESS_API_KEY || '',
        'Content-Type': 'application/json',
      },
    };

    setupInterceptors(this.axiosInstance);
  }

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  private getHeaders(
    headerType?: keyof typeof this.defaultHeaders,
    headers?: Record<string, string>
  ): Record<string, string> {
    let combinedHeaders: Record<string, string> = {};

    if (headerType) {
      combinedHeaders = { ...this.defaultHeaders[headerType] };
    }

    if (headers) {
      combinedHeaders = { ...combinedHeaders, ...headers };
    }

    return combinedHeaders;
  }

  private async request<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    endpoint: string,
    body?: Record<string, any>,
    params?: Record<string, string | number | boolean>,
    headers?: Record<string, string>,
    baseURLType: keyof typeof this.baseURLs = 'default',
    headerType?: keyof typeof this.defaultHeaders // New parameter to specify header type
  ): Promise<AxiosResponse<T>> {
    const url = this.baseURLs[baseURLType] + endpoint;
    const requestHeaders = this.getHeaders(headerType, headers);

    const config: AxiosRequestConfig = {
      method,
      url,
      data: body,
      params,
      headers: requestHeaders, // Directly setting the headers for this request
    };

    const response = await this.axiosInstance.request<T>(config);
    return response;
  }

  public get<T>(
    endpoint: string,
    params?: Record<string, string | number | boolean>,
    baseURLType: keyof typeof this.baseURLs = 'default',
    headerType?: keyof typeof this.defaultHeaders, // New parameter to specify header type
    headers?: Record<string, string>
  ): Promise<AxiosResponse<T>> {
    return this.request<T>('GET', endpoint, undefined, params, headers, baseURLType, headerType);
  }

  public post<T>(
    endpoint: string,
    body: Record<string, any>,
    params?: Record<string, string | number | boolean>,
    baseURLType: keyof typeof this.baseURLs = 'default',
    headerType?: keyof typeof this.defaultHeaders, // New parameter to specify header type
    headers?: Record<string, string>
  ): Promise<AxiosResponse<T>> {
    return this.request<T>('POST', endpoint, body, params, headers, baseURLType, headerType);
  }

  public put<T>(
    endpoint: string,
    body: Record<string, any>,
    params?: Record<string, string | number | boolean>,
    baseURLType: keyof typeof this.baseURLs = 'default',
    headerType?: keyof typeof this.defaultHeaders, // New parameter to specify header type
    headers?: Record<string, string>
  ): Promise<AxiosResponse<T>> {
    return this.request<T>('PUT', endpoint, body, params, headers, baseURLType, headerType);
  }

  public delete<T>(
    endpoint: string,
    params?: Record<string, string | number | boolean>,
    baseURLType: keyof typeof this.baseURLs = 'default',
    headerType?: keyof typeof this.defaultHeaders, // New parameter to specify header type
    headers?: Record<string, string>
  ): Promise<AxiosResponse<T>> {
    return this.request<T>('DELETE', endpoint, undefined, params, headers, baseURLType, headerType);
  }
}

export default ApiService.getInstance();
