import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { requestAccess, signUp } from '../services/Login';
import './AppLogin.scss';

const AppLogin: React.FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [requestingAccess, setRequestingAccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsEmailValid(validateEmail(inputEmail));
  };

  const validateEmail = (inputEmail: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await signUp(email);
      if (response && response.success) {
        navigateToOtp(email);
      } else if (response && response.message) {
        setErrorMessage(response.message);
        setIsEmailValid(false);
      }
    } catch (error) {

    } finally {
      setIsSubmitting(false);
    }
  };

  const navigateToOtp = (email: string) => {
    navigate(`/otp?email=${email}`);
  };

  const handleRequestAccess = async (email: string) => {
    try {
      const response = await requestAccess(email);
      if (response) {
        setEmail('');
        setErrorMessage('');
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        setRequestingAccess(false);
      }, 5000);
    }
  };

  return (
    <div className="login-container">
      <div className="left-side"></div>
      <div className="right-side">
        <div className="email-model">
          <div className="bg"></div>
          {!requestingAccess ? (
            <>
              <h2>Log in to our Video Gallery</h2>
              <h3>Explore the RozieAI video experience library</h3>
              <h4>Email Address</h4>
              <form onSubmit={handleSignUp}>
                <div className="input-wrapper">
                  <input
                    type="email"
                    placeholder="Enter your Email Address"
                    value={email}
                    onChange={handleEmailChange}
                    className={isEmailValid ? 'valid' : ''}
                    required
                  />
                  {errorMessage && errorMessage.includes("don't have your email address")}
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {!errorMessage && (
                  <button type="submit" disabled={!isEmailValid || isSubmitting}>
                    {isSubmitting ? 'Processing...' : 'Proceed'}
                  </button>
                )}
              </form>
            </>
          ) : (
            <>
              <h5>Your request to access the video gallery has been sent.</h5>
              <h6>You will receive an email if and when your request is approved.</h6>
            </>
          )}
          {errorMessage && !requestingAccess && (
            <button onClick={() => handleRequestAccess(email)}>Request Access</button>
          )}

          <p className="sub">
            Disclaimer: By proceeding with login, you agree to receive a one-time password (OTP) via SMS or email to
            authenticate your identity. Your phone number or email address will only be used for the purpose of sending
            this OTP and will not be shared with any third parties. Please ensure that you have access to the provided
            phone number or email ID to complete the login process. If you encounter any issues, please contact our
            support team for assistance.
          </p>
        </div>
        <div className="login-footer">
          <div className="rights">© 2024 RozieAI.</div>

          <div className="Privacy">
            <a href="/assets/docs/RozieAI+Privacy+Policy_web_version_v1.pdf" target="_blank">
              Privacy Policy
            </a>
          </div>
          <div className="social-media">
            <a href="https://www.facebook.com/rozieai/" target="_blank" rel="noreferrer">
              <img src="assets/img/all/facebook-round.svg" alt="Facebook"></img>
            </a>
            <a href="https://twitter.com/RozieAi" target="_blank" rel="noreferrer">
              <img src="assets/img/all/twitter.svg" alt="Twitter"></img>
            </a>
            <a href="https://www.linkedin.com/company/rozieai/" target="_blank" rel="noreferrer">
              <img src="assets/img/all/linkedin.svg" alt="LinkedIn"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLogin;