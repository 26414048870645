import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import ReactGA from 'react-ga4';

import Icons from '../assets';
import { useVideoContext } from '../context/useVideoContext';
import AppHeader from '../header/AppHeader';
import Footer from '../footer/AppFooter';
import AppCardSkeleton from '../cardskeleton/AppCardSkeleton';
import './AppVideos.scss';

interface Video {
  id: number;
  title: string;
  description: string;
  timestamp: string;
  length: string;
  filter: string;
  videoUrl: string;
  videoImage: string;
  duration: string | null;
  thumbnailUrl: string;
}

const AppVideos = () => {
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const navigate = useNavigate();
  const [videos, setVideos] = useState<Video[]>([]);
  const { setVideoInfo } = useVideoContext();

  const cachedDataRef = useRef<Video[] | null>(null);

  const getVideoDuration = useCallback(async (videoUrl: string) => {
    return new Promise<string>((resolve, reject) => {
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;
      videoElement.onloadedmetadata = () => {
        resolve(formatVideoDuration(videoElement.duration));
      };
      videoElement.onerror = (error) => {
        reject(error);
      };
    });
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const idToken = localStorage.getItem('idToken');
      if (!idToken) {
        navigate('/login');
        return;
      }

      if (cachedDataRef.current) {
        setVideos(cachedDataRef.current);
        setLoading(false);
        return;
      }

      const videoAPI = process.env.REACT_APP_VIDEO_API;
      if (!videoAPI) {
        console.error('REACT_APP_API_END_POINT is not defined');
        return;
      }

      const response = await axios.get(videoAPI, {
        headers: {
          Authorization: idToken,
          'Content-Type': 'application/json',
        },
      });
      const videosWithNoDurations = response.data.map((video: Video) => ({
        ...video,
        duration: null,
      }));

      const uniqueFilters: string[] = Array.from(new Set(videosWithNoDurations.map((video: Video) => video.filter)));
      setFilterOptions(uniqueFilters);
      cachedDataRef.current = videosWithNoDurations;
      setVideos(videosWithNoDurations);
      videosWithNoDurations.forEach(async (video: any) => {
        try {
          const duration = await getVideoDuration(video.videoUrl);
          setVideos((prevVideos) =>
            prevVideos.map((v) =>
              v.id === video.id ? { ...v, duration } : v
            )
          );
        } catch (error) {
          console.error(`Failed to load duration for video ${video.id}`, error);
        }
      });
    } catch (error) {
      toast.error('Something Went Wrong, Please try again later');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [getVideoDuration, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [fetchData]);

  const preloadImages = async (urls: string[]) => {
    const promises = urls.map((url) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = url;
      });
    });
    await Promise.all(promises);
  };

  useEffect(() => {
    if (videos.length > 0) {
      const thumbnailUrls = videos.map((video) => video.thumbnailUrl);
      preloadImages(thumbnailUrls);
    }
  }, [videos]);

  const handleFilterClick = (filter: string) => {
    ReactGA.event({
      category: 'Videos',
      action: 'Filter Click',
      label: filter,
    });
    setSelectedFilter(filter);
  };

  const truncateDescription = (desc: string) => {
    const maxLength = 100;
    if (desc.length > maxLength) {
      return desc.substring(0, maxLength) + '...';
    } else {
      return desc || '';
    }
  };

  const handleVideoClick = (video: Video) => {
    setVideoInfo(video.title, video.description, video.videoUrl);
    ReactGA.event({
      category: 'Videos',
      action: 'Video Click',
      label: video.title,
    });
    navigate('/videoplayer');
  };

  const formatVideoDuration = (duration: number) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };


  const filterColorsRef = useRef<{ [key: string]: string }>({});

  useEffect(() => {
    const colors = [
      '#FFDCD5',
      '#FFECD5',
      '#F0FFD5',
      '#DAFFD5',
      '#D5FFFF',
      '#D5D5FF',
      '#EDD5FF',
      '#FFD5DF',
      '#9999FF',
      '#FFFF99',
    ];
    if (Object.keys(filterColorsRef.current).length === 0) {
      const filterColors: { [key: string]: string } = {};
      filterOptions.forEach((filterOption, index) => {
        const availableColors = colors.filter((color) => !Object.values(filterColors).includes(color));
        const randomColor = availableColors[Math.floor(Math.random() * availableColors.length)];

        if (randomColor) {
          filterColors[filterOption] = randomColor;
          const rgb = parseInt(randomColor.substr(1), 16);
          const r = (rgb >> 16) & 0xff;
          const g = (rgb >> 8) & 0xff;
          const b = (rgb >> 0) & 0xff;
          const darkerR = Math.floor(r * 0.8);
          const darkerG = Math.floor(g * 0.8);
          const darkerB = Math.floor(b * 0.8);
          const darkerHex = `#${((darkerR << 16) | (darkerG << 8) | darkerB).toString(16)}`;
          filterColors[`${filterOption}-font`] = darkerHex;
        }
      });
      filterColorsRef.current = filterColors;
    }
  }, [filterOptions]);

  const skeletonCount = loading || videos.length === 0 ? 6 : 0;

  return (
    <div className="main-div">
      <AppHeader></AppHeader>
      <section className="section">
        <div className="gallery-title">
          <h2>Rozie Experience</h2>
          <p>Explore the Rozie Experience Gallery.</p>
        </div>
        <div className="gallery-filters">
          <button
            className={`filter-option ${selectedFilter === 'All' ? 'selected' : ''}`}
            onClick={() => handleFilterClick('All')}
          >
            All
          </button>
          {filterOptions.map((filterOption, index) => (
            <button
              key={index}
              className={`filter-option ${selectedFilter === filterOption ? 'selected' : ''}`}
              onClick={() => handleFilterClick(filterOption)}
            >
              {filterOption}
            </button>
          ))}
        </div>
        <div className="video-gallery">
          {(loading || videos.length === 0) &&
            [...Array(skeletonCount)].map((_, index) => <AppCardSkeleton key={index} />)}
          {videos.map(
            (video) =>
              (selectedFilter === 'All' || selectedFilter === video.filter) && (
                <div className="video-card" key={video.id} onClick={() => handleVideoClick(video)}>
                  <video src={video.videoUrl} poster={video.thumbnailUrl} muted className="video-thumbnail">
                    Your browser does not support the video tag.
                  </video>

                  <div className="video-details">
                    <div className="description">
                      <p>{truncateDescription(video.description)}</p>
                    </div>
                    <div className="length-filter">
                      {video.duration ? (
                        <div className="length">
                          <img src={Icons.playCircle} alt="playimg" />
                          <div>{video.duration ? video.duration : 'Loading...'} mins</div>
                        </div>
                      ) : (
                        <div className="length">
                          <img src={Icons.playCircle} alt="playimg" />
                          <div>{video.duration ? video.duration : '00:00'} mins</div>
                        </div>
                      )
                      }
                      <div
                        className={`filter filter-${video.filter}`}
                        style={{ backgroundColor: filterColorsRef.current[video.filter] }}
                      >
                        {video.filter}
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default AppVideos;
