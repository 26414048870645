import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Icons from '../assets';
import { useVideoContext } from '../context/useVideoContext';
import AppHeader from '../header/AppHeader';
import Footer from '../footer/AppFooter';
import './AppVideoPlayer.scss';

const AppVideoPlayer: React.FC = () => {
  const { title, description, videoUrl } = useVideoContext();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBackButtonClick = () => {
    ReactGA.event({
      category: 'Video Player',
      action: 'Go Back Click',
    });
    navigate('/videos');
  };

  return (
    <div className="main-div">
      <AppHeader></AppHeader>
      <div className="video-player">
        <div className="button-container" onClick={handleGoBackButtonClick}>
          <img src={Icons.back} alt="back button" />
          <button> Go Back</button>
        </div>
        <h2 className="video-title">{title}</h2>
        <p className="video-description">{description}</p>
        <div className="video-container">
          <video className="video" autoPlay controls controlsList="nodownload">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AppVideoPlayer;

