/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './AppFooter.scss';
import Icons from '../assets';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-description logo-column">
          <div className="logo">
            <a href="https://www.rozie.ai/home" target="_blank" rel="noreferrer">
              <img src={Icons.rozieLogo} alt="logo" />
            </a>
          </div>
          <div className="description">
            Rozie<sup>AI</sup> is a leader in smart customer experience solutions, empowering organizations to deliver personalized
            experiences at scale. With a suite of offerings that includes intelligence and insights, digital self-service,
            agent augmentation, and journey and workflow automation,
            Rozie<sup>AI</sup> leverages advanced AI and behavioral science to drive innovation in customer experience. From modernizing customer experience strategies to enhancing AI capabilities,
            Rozie<sup>AI</sup> provides plug-and-play solutions, operational best practices, and advisory services for CX excellence.
          </div>

          <div className="rights">
            © 2024 Rozie<sup>AI</sup>. All rights reserved.
          </div>
          <div className="social-media">
            <a href="https://www.linkedin.com/company/rozieai/" target="_blank" rel="noreferrer">
              <img src={Icons.linkedinIcon} alt="img" />
            </a>
          </div>
        </div>

        <div className="footer-main-links">
          <div className="footer-column">
            <div className="footer-link-title">
              <a href="#">Industry Focus</a>
            </div>
            <div className="footer-link">
              <a href="https://www.rozieai.com/travelcx" target="_blank" rel="noreferrer">
                Travel
              </a>
            </div>
            <div className="footer-link">
              <a href="https://www.rozieai.com/overall-insurance" target="_blank" rel="noreferrer">
                Insurance
              </a>
            </div>
            <div className="footer-link">
              <a href="https://www.rozieai.com/healthcare" target="_blank" rel="noreferrer">
                Healthcare
              </a>
            </div>
          </div>
          <div className="footer-column ">
            <div className="footer-link-title">
              <a href="#">In the News</a>
            </div>
            <div className="footer-link">
              <a href="https://finance.yahoo.com/news/rozieai-intelligent-experience-orchestrator-selected-055000726.html?guccounter=1" target="_blank" rel="noreferrer">
                The RozieAI Intelligent Experience Orchestrator Selected by Air Canada to Help Simplify Travel
              </a>
            </div>
            <div className="footer-link">
              <a href="https://finance.yahoo.com/news/rozieai-unveils-early-access-future-212600431.html" target="_blank" rel="noreferrer">
                RozieAI Unveils Early Access to the Future of Insurance Self-Service at ITC Vegas
              </a>
            </div>
            <div className="footer-link">
              <a href="https://www.marketwatch.com/press-release/world-aviation-festival-2024-partners-with-rozieai-to-elevate-attendee-experience-8aaaf045" target="_blank" rel="noreferrer">
                World Aviation Festival 2024 Partners With RozieAI to Elevate Attendee Experience
              </a>
            </div>
          </div>
          <div className="footer-column ">
            <div className="footer-link-title">
              <a href="#">Contact</a>
            </div>
            <div className="footer-link">
              <a href="#">+1 323-400-5101</a>
            </div>
            <div className="footer-link">
              <a href="mailto:hello@rozie.ai" target="_blank" rel="noreferrer">
                hello@rozie.ai
              </a>
            </div>
            <div className="footer-link">
              <a href="#">201 W Main St, Durham, NC 27701, United States</a>
            </div>
            <div className="footer-link">
              <p className="privacy">
                <a href="/assets/docs/6645a0c82287a53a49b3961d_Rozie-Privacy-Policy.pdf" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="mobile-footer">
        <div className="rights">© 2024 RozieAI.</div>

        <div className="Privacy">
          <a href="/assets/docs/6645a0c82287a53a49b3961d_Rozie-Privacy-Policy.pdf" target="_blank">
            Privacy Policy
          </a>
          {/* <a
            href="https://www.rozie.ai/s/RozieAI-Privacy-Policy_web_version_v1.pdf"
            target="_blank"
            rel="noreferrer"
          ></a> */}
        </div>

        <div className="social-media">
          <a href="https://www.facebook.com/rozieai/" target="_blank" rel="noreferrer">
            <img src="assets/img/all/facebook-round.svg" alt="Facebook"></img>
          </a>
          <a href="https://twitter.com/RozieAi" target="_blank" rel="noreferrer">
            <img src="assets/img/all/twitter.svg" alt="Twitter"></img>
          </a>
          <a href="https://www.linkedin.com/company/rozieai/" target="_blank" rel="noreferrer">
            <img src="assets/img/all/linkedin.svg" alt="LinkedIn"></img>
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
