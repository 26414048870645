import icons from '../assets/index';

export const TEMPLATE_DATA = [
  {
    type: 'summary',
    title: 'Summary',
    icon: icons.summaryIcon,
  },
  {
    type: 'key-takeaways',
    title: 'Key Takeaways',
    icon: icons.keyTakeAwayIcon,
  },
  {
    type: 'recommendations',
    title: 'Recommendations',
    icon: icons.recommendationIcon,
  },
  {
    type: 'topics',
    title: 'Topics',
    icon: icons.topicIcon,
  },
];
